/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  /* src: url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.eot'); */ /* IE9 Compat Modes */
  src: local(''),
    /* ('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.eot?#iefix') format('embedded-opentype'), */
      /* IE6-IE8 */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto-condensed-v24-latin/roboto-condensed-v24-latin-regular.svg#RobotoCondensed')
      format('svg'); /* Legacy iOS */
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  /* src: url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.eot'); */ /* IE9 Compat Modes */
  src: local(''),
    /* url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.eot?#iefix') format('embedded-opentype'), */
      /* IE6-IE8 */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto-mono-v13-latin/roboto-mono-v13-latin-regular.svg#RobotoMono')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 400;
  /* src: url("021e919fd2310de5f9ad257e4b9be77e.eot"); */ /* IE9*/
  src: local(''),
    /* url("021e919fd2310de5f9ad257e4b9be77e.eot?#iefix") format("embedded-opentype"), */
      /* IE6-IE8 */ url('../fonts/roboto-medium/roboto-medium.woff2')
      format('woff2'),
    /* chrome、firefox */ url('../fonts/roboto-medium/roboto-medium.woff')
      format('woff'),
    /* chrome、firefox */ url('../fonts/roboto-medium/roboto-medium.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('../fonts/roboto-medium/roboto-medium.svg#Roboto Medium')
      format('svg'); /* iOS 4.1- */
}
